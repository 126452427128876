<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-1">
              <div class="col-12 section_title">CARGA DE OC</div>
            </div>
          </template>

          <template v-slot:end>
            <Button
              label="GUARDAR"
              icon="pi pi-save"
              class="p-button-success mr-2"
              @click="save()"
            />
          </template>
        </Toolbar>


        <div class="">
          <div class="formgrid grid">
            <div class="field col-1">
 
                <label for="base">N° de Presupuesto</label>
                <AutoComplete
                  v-model="selectedBudget"
                  :suggestions="filteredBudgets"
                  @complete="searchBudgets($event)"
                  field="id"
                  :dropdown="true"
                  @item-select="selectBudget"
                  
                  id="base"
                  class="inputfield w-full"
                >

                 <template #item="slotProps">
                    <div class="flex">
                         <div class="ml-2">{{slotProps.item.fecha_ingreso }}</div>
                        <div class="ml-2 font-bold">#{{slotProps.item.id}}</div>
                        <div class="ml-auto">{{slotProps.item.client_name}}</div>
                    </div>
                </template>

                </AutoComplete> 
 

            </div>
            <div class="field col-1">

              <label for="base">N° de OC</label>
              <InputText
                v-model="ocNumber"
                id="base"
                class="inputfield w-full"
              />
            </div>
            <div class="field col-2">

              <label for="base">Adjunto</label>
                <!-- <FileUpload mode="basic" name="adjunto" :customUpload="true" id="adjunto" /> -->
                <input type="file" id="adjunto" name="adjunto" class="inputfield w-full" />
            </div>
              <div class="field col-3">
                <label for="base">Cliente</label>
                <AutoComplete
                  v-model="selectedClient"
                  :suggestions="filteredClients"
                  @complete="searchClient($event)"
                  field="name"
                  :dropdown="true"
                  @item-select="newClient = selectedClient"
                  id="base"
                  class="inputfield w-full"
                />
              </div>
              <div class="field col-1">
                <label for="formu">Moneda</label>
                <SelectButton v-model="form.moneda" :options="monedas"  optionLabel="name" />
              </div>
                <div class="field col-2">
              <label for="detail">U$D Banco Nación</label>
              <InputNumber
                mode="decimal"
                :minFractionDigits="2"
                v-model="this.p_dolar"
                id="detail"
                type="text"
                class="inputfield"
              />
              <div>
                <small> U$D Sugerido: {{ this.dolar_suggested }}</small>
              </div>
            </div>
              
              <div class="field col-2">
                <label for="impuesto">Impuesto</label>
                <SelectButton v-model="form.impuesto" :options="impuestos"  optionLabel="name" />
              </div> 

            
<!-- 
            <div class="field col-1">
              <label for="entrega"> Entrega </label>
              <Calendar
                id="entrega"
                v-model="form.entrega"
                class="inputfield w-full"
              />
            </div> -->

            <div class="field col-1">
              <label for="formu">Envío</label>
              <SelectButton v-model="form.envio" :options="[{ value: 0, name: 'Si'},{ value: 1, name: 'No'}]"  optionLabel="name" />
            </div>    
            
            <div class="field col-2 ">
                <label for="fdp">Forma de pago</label>
                             <InputText id="formu" v-model="form.selectedFdp"  class="inputfield w-full" />
 
              </div> 
                          <div class="field col-1 ">
                <label for="fdp">Entrega estimada</label>
                             <InputText id="formu" v-model="form.fecha_entrega"  class="inputfield w-full" />
 
              </div> 
              <div class="field col-1">
              <label for="descuento">Descuento</label>
              <InputNumber
                id="descuento"
                v-model="form.descuento"
                class="inputfield w-full"
              />
            </div>
            <div class="field col-12">
              <label for="formu">Observación</label>
              <InputText id="formu" v-model="form.observacion" class="inputfield w-full" />
            </div>




        </div>  
        </div>  

      </div>
    </div>
    <div class="col-12">


            
      <div class="card">
        <div class="my-1">
          <div class="col-12 section_subtitle">Items <small> </small></div>
        </div>

        <div class="">
          <div class="formgrid grid">
            <div class="field col-3">
              <label for="base">Producto</label>
              <AutoComplete
                v-model="selectedProduct"
                :suggestions="filteredProducts"
                @complete="searchProduct($event)"
                field="name"
                :dropdown="true"
                :value="name"
                @item-select="selectedItem(selectedProduct)"
                @item-unselect="newItem = null"
                id="base"
                class="inputfield w-full"
              >
              <template #item="slotProps">
                    <strong>

                      {{ slotProps.item.code }}
                    </strong>
                     - {{ slotProps.item.name }}

                 </template>
                 </AutoComplete>

            </div>
            <div class="field col-3">
                <label for="formu">Cantidad</label>
                <InputText
                 :disabled="!selectedProduct"
                  v-model="qtyToAdd"
                  id="formu"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />
              </div>
            <div class="field col-3">
                <label for="formu w-full">Valor</label>
                <InputText
                 :disabled="!selectedProduct"
                  v-model="valorToAdd"
                  id="formu"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />
            </div>
            <div class="field col-1  ">
              <div class="relative w-3rem h-4rem mx-3 my-3 md:my-0 border-round">
            <div class="absolute bottom-0 left-0 text-white font-bold flex align-items-center justify-content-center border-round">
              
<Button v-if="selectedProduct && selectedProduct.id" @click="editPiece(selectedProduct.id)"  
              icon="pi pi-pencil" class="p-button-sm p-button-info" />
              </div>

            </div>
        </div>
              <div class="flex flex-wrap align-items-center justify-content-center">

              
            </div> 


        <div class="field col-2">
        
            <Button :disabled="!qtyToAdd"  @click="addItem(newItem)" label="Agregar" 
              icon="pi pi-plus" class="p-button-success inputfield w-full mt-5" />

        </div>



          </div>
        </div>

        <div class="">
          <DataTable
            :value="items"
             dataKey="id" 
            class="p-datatable-sm"
            responsiveLayout="scroll"
          >
            
            <Column header="" >
              <template #body="slotProps">
                  <Button icon="pi pi-times" class="p-button-rounded p-button-danger" @click="delItem(slotProps.data)"></Button>

                  <Button v-if="slotProps.data.plane" icon="pi pi-file-pdf" class="p-button-rounded p-button-info" @click="showPlane(plane.file)"></Button>
                  <!-- <div v-for="plane in slotProps.data.planos" :key="plane.id">
                     <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info" @click="showPlane(plane.file)"></Button>
                  </div> -->
              </template>
            </Column>
            
           <Column headerClass="" field="qty" header="Cantidad" headerStyle="max-width: 20%">
              <template #body="slotProps">
                <InputText 
                  v-model="slotProps.data.qty"
                  id="iqty"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />
              </template>
            </Column>
            <Column field="piece_code" header="Código">
              <template #body="slotProps">
                <b>

                  {{ slotProps.data.piece_code }}
                </b>
              </template>
            </Column>
            <Column field="name" header="Detalle">
            <template #body="slotProps" >
              <InputText 
                  v-model="slotProps.data.name"
                  id="imp"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />
            </template>
            </Column>

            <Column field="material_id" header="Material a utilizar">
              <template #body="slotProps" >
                <!-- <div v-if="slotProps.data.formula.material_name">{{ slotProps.data.formula.material_name }}   </div>   -->
                <!-- {{ slotProps.data.mp }}
                {{ slotProps.data }} -->
                <InputText 
                  v-model="slotProps.data.mp"
                  id="imp"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />
                <!-- <AutoComplete
                  v-model="slotProps.data.material_id"
                  :suggestions="filteredStock"
                  @complete="searchStock($event)"
                  field="name"
                  :dropdown="true"
                  id="base"
                  class="inputfield w-full"
                /> -->
                <div class="field-checkbox">
            <Checkbox :id="'mpc' + slotProps.index" :inputId="'mpc' + slotProps.index" v-model="slotProps.data.mpc" :binary="true"
            @change="changeMpc(slotProps.data, slotProps.index)" />
            <label :for="'mpc' + slotProps.index"> A cargo del cliente </label>

            ( {{ slotProps.data.matCost }} )
        </div>

              </template>

            </Column>

            <Column field="fest" header="Fecha prevista">
            
              <template #body="slotProps" >
            <InputText
                id="entrega"
                v-model="slotProps.data.fest"
                class="inputfield w-full"
              />
</template>
            </Column>

            <Column field="valor" header="Valor">
            
            <template #body="slotProps">


                    <div class="flex justify-content-between">
                          <InputText
                  v-model="slotProps.data.valor"
                  id="formu"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />

                      <!-- <div>
                        <span>{{ toSelectedValue(slotProps.data.valor) }}</span>
                      </div> -->
                      <!-- <div>
                        <span>{{toArs(valorByClientType(slotProps.data.valor) )}}</span>
                      </div> -->
                    </div>
                
                </template>

            </Column>
             <!-- <Column field="unidad" header="Unidad"></Column> -->
            <Column header="Subtotal">
                <template #body="slotProps">
                    <div class="flex justify-content-between">
                      <div>
                        <div>
                    <span>{{ toSelectedValue(slotProps.data.valor  * slotProps.data.qty) }}</span>
                  </div>
                      </div>
                    </div>
                
                </template>
            ></Column>
          </DataTable>

        </div>
      </div>

      <div class="card">
        <div class="mx-1">
          <div class="col-4 section_subtitle p-ml-auto">Totales <small> </small></div>
        </div>


          <DataTable
            :value="calculos"
            dataKey="id" 
            class="p-datatable-sm"
            responsiveLayout="scroll"
          >
            <Column field="name" header="Detalle"></Column>
            <Column field="valor" exportableheader="Subtotal">
                <template #body="slotProps">
                    <div class="flex justify-content-between">
                      <div>
                        <span>{{toSelectedValue(slotProps.data.valor)}}</span>
                      </div>
                    </div>
                
                </template>
            ></Column>



          </DataTable>


      </div>  


    </div>


  </div>
</template>

<script>
import CrudService from "./../../services/crud.service.js";


export default {
  data() {
    return {
      ocNumber: "",
      monedas: [{ value: 0, name: 'AR$'},{ value: 1, name: 'U$D' }],
      impuestos: [{ value: 0, name: '0%'},{ value: 0.21, name: '21%'},{ value: 0.105, name: '10.5%'}],
      routeName: null,
      clientType: {value: 0, name: 'Consumidor Final'},
      configuraciones: [],
      qtyToAdd: 1,
      valorToAdd: 1,
      selectedFdp: null,
      form: { 
        impuesto: {value: 0.21, name: '21%'},
        envio: {value: 0, name: 'Si'},
        moneda: {value: 0, name: 'AR$'},
        observacion: ' PRUEBA ',
        

      }, 
      items: [],

      actualFormula: "",
      products: null,
      filteredProducts: null,
      newItem: null,
      selectedProduct: null,

      Clients: null,
      filteredClients: null,
      newItem: null,
      selectedClient: null,

      Budgets: null,
      filteredBudgets: null, 
      selectedBudget: null,

      stock: null,
      filteredStock: null, 
      

      mods: null,
      newItemMOD: null,
      filteredMod: null,
      selectedMod : null,
      actualFormulaMOD: "",
      
      modsMarco: null,
      newItemMODMarco: null,
      filteredModMarco: null,
      selectedModMarco : null,
      actualFormulaMODMarco: "",

      submitted: false,
      validationErrors: null,
 
      ciif: 0,
      mpi: 0,
      moi: 0 ,
      //calcQuantity: 0,
 
      formasdepago: [ 
      ],
      materials: [],
      p_dolar: 1,
      dolar_suggested: 1,

    };
  },
  computed: {
    dolarValue() {
      switch (this.form.moneda.value) {
        case 1:
          return this.configuraciones.dolar_billete;
        case 3:
          return this.configuraciones.dolar_divisa; 
        default:
          return this.configuraciones.dolar_billete;
      }

    },
    totalItems() {
      let total = 0;

      this.items.forEach((item) => {
        //console.log(item);
        //if(item.mpc){
        //  total += (Number(item.valor) - item.matCost) * item.qty;
        //}else{
          total += Number(item.valor) * item.qty;
        //}
      });

      return total;
    },
    totalMOD() {
      
      let total = 0;

      this.mod.forEach(item => {
        console.log(item)
        total += item.valor * item.qty;
      });

      return total;

    },
    calculos() {
// Costo de Produccion
// MPI
// MOI
// CIIF
// Costo de General

      let data = 
      [
        {
          name: "Subtotal",
          valor: this.totalItems
        },
       /* {
          name: "IVA",
          valor: this.mpi
        },
        {
          name: "Otros Impuestos",
          valor: this.moi
        },
        {
          name: "Total",
          valor: this.ciif
        },*/
        // {
        //   name: "Costo de General",
        //   valor:  this.totalMPD + this.totalMOD + this.mpi + this.moi + this.ciif
        // },
      ];

 
    
      if(this.form.descuento > 0) {
        // to negative
        data.push({
          name: "Descuento",
          valor: this.totalItems * (-this.form.descuento / 100)
        })
      }
      // check if items has mpc
      let hasMPC = false;
      let mpctotal = 0;
      this.items.forEach((item) => {
        if (item.mpc > 0) {
          hasMPC = true;
          mpctotal += item.matcost * item.qty;
        }
      });

      if(this.form.impuesto.value > 0) {

        let descuento = 0;
        if(this.form.descuento > 0) {
          descuento = this.totalItems * (-this.form.descuento / 100);
        }


        data.push({
          name: "Impuesto",
          valor: (this.totalItems + descuento) * this.form.impuesto.value
        })
      }

// sum all data valor
      let total = 0;
      data.forEach(item => {
        total += item.valor;
      });

      data.push({
        name: "Total",
        valor: total
      });
      return data

      
    },

  },
  created() {
    // get route name
    this.routeName = this.$route.name;
  },
  mounted() {

    CrudService.getCRUD("api/cotizacion").then(
      (data) => (this.Budgets = data)
    );
    CrudService.getCRUD("api/clients").then(
      (data) => (this.clients = data)
    );

    CrudService.getCRUD("api/pieza").then(
      (data) => (this.products = data)
    );

     CrudService.getCRUD("api/stock").then(
      (data) => (this.stock = data)
    );

    CrudService.getCRUD("api/costos").then((data) => 
    {
    //console.log(data.bna.dolar.value)
      this.dolar_suggested = data.bna.dolar.value;

      this.p_dolar = parseFloat(data.bna.dolar.value);
      
    }).then(() => {

      this.loaded = true
    });


  },
  methods: {
    changeMpc(item, index) {

      console.log(item)

      
      if (item.mpc) {
        this.items[index].valor = Number(item.valor) - Number(item.matCost);
        
      } else {
        this.items[index].valor = Number(item.valor) + Number(item.matCost);
      }

      this.items[index].valor = Number(this.items[index].valor).toFixed(2);

    },
    selectedItem(selectedProduct) {
      this.newItem = null;
      this.valorToAdd = selectedProduct.last_price;
      if (selectedProduct.formula) {
        this.mpnameToAdd = selectedProduct.formula.material_name;
      }
    },
    selectBudget(budget) {
      this.form.observacion = this.selectedBudget.obs;

      this.form.impuesto = this.impuestos.find(item => item.value == this.selectedBudget.impuesto);
      this.form.moneda = this.monedas.find(item => item.value == this.selectedBudget.moneda);

      this.form.envio = this.selectedBudget.envio;

      this.form.descuento = this.selectedBudget.descuento;

      let dateE = new Date(this.selectedBudget.fecha_entrega);
      this.form.fecha_entrega = this.selectedBudget.fecha_entrega //dateE.getDate() + "/" + (dateE.getMonth() + 1) + "/" + dateE.getFullYear();
      

      this.selectedClient = this.clients.find(item => item.id == this.selectedBudget.client_id);

      this.items = this.selectedBudget.items

      // if items has mpc set valor to matCost
      this.items.forEach((item) => {
        if (item.mpc ) {
          item.valor = Number(( item.valor - item.mpcost).toFixed(2));

        }
      });



    },
    valorByClientType(valor) {
      if (this.clientType.value == 0) {
        return valor
      } else {
        return valor * 0.3 + valor
      }
    },

    costoProd(){

      let costo = 0;

      return costo;

    },
    toArs(value) {
 
      return value.toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      });
    },
    toUsd(value) {
      value = value / this.p_dolar;
      // format number to currency
      return value.toLocaleString("es-AR", {
        style: "currency",
        currency: "USD",
      });

    },
    toSelectedValue(value){

      if(this.form.moneda.value == 0){
        return this.toArs(value.toFixed(2))
      }else{
        return this.toUsd(value.toFixed(2))
      }
      
    },
    showPlane(file) {
      
      // open window
      window.open(file, "_blank");
      
      

    },
delItem(itemId){
  //console.log(item)
  // find in this.items
  let index = this.items.findIndex(item => item.id == itemId);
  // remove item
  this.items.splice(index, 1);

  // recalculate total


}, 
      formatUnity(value) {

           switch(value) {
                case 0:
                    return "ml";
                case 1:
                    return "unidad";
                case 2:
                    return "kg";
                case 3:
                    return "mt";
                case 4:
                    return "lt";
                case 5:
                    return "m2";
                case 6:
                    return "mtl";
                case 7:
                    return "gr";
                case 8:
                    return "juego";
                default:
                    return "";
            }
      },
    displayErrors(errors) {
      for (let key in errors) {
        this.validationErrors.push({ field: key, message: errors[key] });

        let error = errors[key];
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: error[0],
          life: 3000,
        });
      }
    },
    searchStock(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredStock = [...this.stock];
        } else {
          this.filteredStock = this.stock.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchProduct(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredProducts = [...this.products];
        } else {
          this.filteredProducts = this.products.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase()) || prod.code.startsWith(event.query);
          });
        }
      }, 250);
    },
    searchClient(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredClients = [...this.clients];
        } else {
          this.filteredClients = this.clients.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    
    searchBudgets(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredBudgets = [...this.Budgets];
        } else {
          this.filteredBudgets = this.Budgets.filter((prod) => {
            return prod.id == event.query || prod.client_name.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchMDO(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredMod = [...this.mods];
        } else {
          this.filteredMod = this.mods.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    addItem() {
 
        // check if pieza_id exists on this.items
        let item = this.items.find(item => item.pieza_id == this.selectedProduct.id);

        // check if item exists
        let existentItem = item;


      if(item){
        // if exists add qty
        item.qty += Number(item.qty) + Number(this.qtyToAdd);
        
        this.newItem = null;
        this.qtyToAdd = 1;
        this.valorToAdd = 1;
        this.selectedProduct = null;
 
        return
      }
        

        // check if selected product lenght
        if (!this.selectedProduct.length && !existentItem) {
          this.items.push({
            pieza_id: this.selectedProduct.id,
            name:  this.selectedProduct.name,
            qty: this.qtyToAdd,
            valor: this.valorToAdd,
            piece_code: this.selectedProduct.code,
            planos: this.selectedProduct.planos,
            matCost: this.selectedProduct.formula.materiales.reduce(
              (a, b) => a + Number (b.valor),
            0)
          });
        }else{

          this.items.push({
            id: 0,
            name: this.newItem ? this.newItem : this.selectedProduct,
            qty: this.qtyToAdd,
            code: this.selectedProduct.code,
            valor: this.valorToAdd,
            matCost: this.selectedProduct.formula.materiales.reduce(
              (a, b) => a + Number (b.valor),
            0)
  
          });

        }

        this.newItem = null;
        this.qtyToAdd = 1;
        this.valorToAdd = 1;
        this.selectedProduct = null;
 
    },
    save() {
      this.submitted = true;
 
      let formData = new FormData();
      if(this.selectedClient == null){
        this.$toast.add({
          severity: "error",
          
          //summary: "Error!",
          summary: "Debe seleccionar un cliente",
          life: 3000,
        });
        return;
      }else{

        formData.append("cliente", this.selectedClient.id);
      }
      formData.append("dolar_value", this.p_dolar);

      formData.append("observacion", this.form.observacion);

      formData.append("fecha_entrega", this.form.fecha_entrega);

      formData.append("oc_number", this.ocNumber);

      // // parse date for php
      // let date = new Date(this.form.validez);
      // let day = date.getDate();
      // let month = date.getMonth() + 1;
      // let year = date.getFullYear();
      // let validez = year + "-" + month + "-" + day;
      formData.append("validez", this.form.validez);

      formData.append("moneda", this.form.moneda.value);
      formData.append("impuesto",this.form.impuesto.value);
      formData.append("descuento",this.form.descuento);
      formData.append("envio",this.form.envio.value);

      formData.append("items", JSON.stringify(this.items));

      //get file from input adjunto
      let file = document.getElementById("adjunto").files[0];
      formData.append("adjunto", file);


      CrudService.createCRUD("api", "ventas", formData).then(
        (data) => {
          if (data.status == "success") {

this.$toast.add({
  severity: "success",
  summary: "Éxito!",
  detail: "Creada correctamente.",
  life: 3000,
});

this.$router.push("/ventas");

} else {

for (var key in data.data.errors) {
  if (data.data.errors.hasOwnProperty(key)) {
    this.$toast.add({
      severity: "error", 
      summary: data.data.errors[key][0],
      life: 3000,
    });
  }
}


}


         // }
        }
      );


    },
  },
};
</script>


<style scoped>

  .section_subtitle {
    font-size: 1.2rem;
    font-weight: bold;
    color: #1261b1;
    margin-bottom: 1rem;
  }

  .card {
    padding-top: 0.25rem;
  }

</style>